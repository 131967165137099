import React, {useEffect, useState} from 'react';
import {Days} from "../../Store/days/days";
import classes from "./Modal.module.scss";


const Modal = ({day, setModal, setLastOpenedBox}) => {
    let likedContent = JSON.parse(localStorage.getItem('liked')) || []
    const [content] = useState(Days.find(item => item.id === parseInt(day)))
    const [heart, setHeart] = useState(likedContent.includes(day));


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const like = (day) => {
        if (likedContent.includes(day)) {
            likedContent = likedContent.filter(e => e !== day);
            setHeart(false);
        } else {
            likedContent.push(day);
            setHeart(true);
        }
        localStorage.setItem('liked', JSON.stringify(likedContent))

    }

    return (
        <>
            <div
                className={'absolute flex justify-center items-center left-0 right-0 top-0 min-h-screen z-[150] bg-black/80 md:fixed'}>
                <div
                    className={"max-w-[950px] md:max-h-[95vh] md:mx-auto relative md:rounded-xl p-10 md:px-28 md:py-10 md:overflow-y-auto rounded-lg m-5 " + classes['bg-image']}>
                    <button onClick={() => {
                        setModal({
                            dayId: null,
                            isVisible: false
                        })
                        setLastOpenedBox(day)
                    }}><img src="assets/X.png" alt="" className={'absolute right-5 top-5'}/>
                    </button>
                    {content.content}
                    <div className={'flex justify-center mt-4'}>
                        <div
                            className={`btn-primary w-fit px-2 md:px-16 py-2 cursor-pointer gap-2 ${heart ? '' : "gtm_like"}`}
                            data-likeday={day}
                            onClick={() => like(day)}>{heart ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="25.79" height="20.977"
                                 viewBox="0 0 25.79 20.977">
                                <path id="Path_6928" data-name="Path 6928"
                                      d="M44.35,72.378c-1.069-7.068,8.979-11,12.808-4.809,4.441-6.794,15.085-1.4,12.506,6.115-2.394,5.344-7.6,8.853-12.461,11.754-5.133-3.087-11.174-7.026-12.852-13.06"
                                      transform="translate(-44.271 -64.461)" fill="#ff3162"/>
                            </svg>
                            : <svg xmlns="http://www.w3.org/2000/svg" width="25.79" height="20.977"
                                   viewBox="0 0 25.79 20.977">
                                <path id="Path_6928" data-name="Path 6928"
                                      d="M44.35,72.378c-1.069-7.068,8.979-11,12.808-4.809,4.441-6.794,15.085-1.4,12.506,6.115-2.394,5.344-7.6,8.853-12.461,11.754-5.133-3.087-11.174-7.026-12.852-13.06"
                                      transform="translate(-44.271 -64.461)" fill="#fff"/>
                            </svg>} Tetszik ez a
                            tartalom!
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Modal;
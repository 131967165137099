import Game from "../../Components/Game/Game";

export const Days = [{
    id: 1, content:
        <>
            <h2>1. nap</h2>
            <div>
                <img src="assets/Video_2-11-25.gif" alt="Gif" className={''}/></div>
        </>
},
    {
        id: 2, content:
            <>
                <h2>Ragacsos hasselback sütőtök</h2>
                <div>
                    <img src="assets/ragacsos-hasselback-sutotok.jpg" alt="" className={'my-4 rounded'}/>
                </div>
                <h4>Mindenki ismeri a hasselback burgonyát… vagy ha nem, akkor keress rá nálunk, hiszen arra is van
                    receptünk! Ez most egy sütőtökös verzió, ami a karácsonyi asztal egyik legizgalmasabb köreteként fog
                    ragyogni. </h4>
                <div className="grid md:grid-cols-4 ">
                    <div>
                        <h3>Hozzávalók</h3>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>1 db sütőtök</li>
                            <li>2 ek barna cukor</li>
                            <li>2 tk füstölt fűszerpaprika</li>
                            <li>½ db narancs leve</li>
                            <li>20 g dió</li>
                            <li>250 g vaj</li>
                            <li>2 ek juharszirup</li>
                            <li>½ csokor kakukkfű
                            </li>
                            <li>só, bors</li>
                            <li>nagy szemű só
                            </li>
                        </ul>
                    </div>
                    <div className={'md:col-span-3'}>
                        <p>A hasselback sütőtökhöz a sütőtököt megpucoljuk, félbevágjuk és eltávolítjuk a magjait.
                            Mindkét oldalára egy-egy evőpálcikát helyezünk, hogy amikor bevagdossuk a tököt, ne vágjuk
                            végig teljesen. Kb. 5 mm-enként vágjuk be az elejétől a végéig. Ezt megismételjük a másik
                            felével is.
                        </p>
                        <p>
                            Egy lábasban közepes lángon összeolvasztjuk a vajat, a barna cukrot, a juharszirupot, a
                            füstölt paprikát, a friss kakukkfű felét és a narancs levét. Sózzuk, borsozzuk és amikor a
                            cukor teljesen felolvadt, egy tepsit kibélelünk sütőpapírral, ráhelyezzük a bevagdosott
                            tököket, majd megkenegetjük a fűszeres vajjal – de ne használjuk el az összeset, mert sütés
                            közben még kétszer meg fogjuk kenni a hasselback sütőtökünket.
                        </p>
                        <p>
                            180 fokos sütőben 45 perc alatt elkészül a sütőtök, úgy, hogy 20 percenként bekenegetjük a
                            fűszeres vajunkkal.
                        </p>
                        <p>
                            Miután elkészült, megszórjuk aprított dióval, a maradék friss kakukkfűvel és nagy szemű
                            sóval, és már mehet is a karácsonyi asztalra!
                        </p>
                    </div>
                </div>
            </>
    },
    {
        id: 3, content:
            <>
                <h2>3. nap</h2>
                <div>
                    <img src="assets/3_csempe.png" alt="" className={'rounded hidden md:block'}/>
                    <img src="assets/3_csempe_mobil.png" alt="" className={'rounded md:hidden'}/>
                </div>
            </>
    },
    {
        id: 4, content:
            <>
                <Game game={"gifts/index.html"} title={"Karácsonyi játék"}/>
            </>
    },
    {
        id: 5, content:
            <>
                <h2>Hangolódj az Ünnepekre!</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/jmZeTx4J1Ew"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/jmZeTx4J1Ew"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <h4>Fogadd szeretettel ezt a karácsonyi zenét, és éld át az Ünnepek hangulatát.</h4>
            </>
    },
    {
        id: 6, content: <>
            <h2>5 magyar kézműves akiket érdemes követni, nem csak az ünnepek alatt.</h2>
            <div className={'mt-6 mb-4'}>
                <div className={'flex flex-col md:flex-row md:items-center justify-between mb-4'}>
                    <div>
                        <h4 className={'mb-2'}>1. Milyenkedves</h4>
                        <p>Természetközeli minták, azoknak akik szeretik az erdőt, a növényeket, állatokat,
                            madarakat. </p></div>
                    <div className={'flex flex-col items-end'}>
                        <a href="https://milyenkedves.com/" target="_blank"
                           className="btn-primary w-fit px-5 px-2">Megnézem</a>
                    </div>
                </div>
                <div className={'flex flex-col md:flex-row md:items-center justify-between mb-4'}>
                    <div>
                        <h4 className={'mb-2'}>2. Vatesprojekt</h4>
                        <p>A magyar irodalom, költészet és mesék szerelmeseinek.</p></div>
                    <div className={'flex flex-col items-end'}>
                        <a href="https://vates.hu/" target="_blank"
                           className="btn-primary w-fit px-5 px-2">Megnézem</a>
                    </div>
                </div>
                <div className={'flex flex-col md:flex-row md:items-center justify-between mb-4'}>
                    <div>
                        <h4 className={'mb-2'}>3. Susu keramika</h4>
                        <p>#playclay #Flora&Szaffi #makers #slow designers #based in Budapest</p></div>
                    <div className={'flex flex-col items-end'}>
                        <a href="https://www.instagram.com/susu_keramika/" target="_blank"
                           className="btn-primary w-fit px-5 px-2">Megnézem</a>
                    </div>
                </div>
                <div className={'flex flex-col md:flex-row md:items-center justify-between mb-4'}>
                    <div>
                        <h4 className={'mb-2'}>4. Babkamanufaktúra</h4>
                        <p>A kézzel festett kerámiák bűvöletében.</p></div>
                    <div className={'flex flex-col items-end'}>
                        <a href="https://www.babkamanufaktura.hu/" target="_blank"
                           className="btn-primary w-fit px-5 px-2">Megnézem</a>
                    </div>
                </div>
                <div className={'flex flex-col md:flex-row md:items-center justify-between'}>
                    <div><h4 className={'mb-2'}>5. Nagyimutatta</h4>
                        <p>„Mi elkészítjük az egyik legcoolabb kézzel hímzett pólódat, ruhádat“</p></div>
                    <div className={'flex flex-col justify-center items-end'}>
                        <a href="https://nagyimutatta.hu/" target="_blank"
                           className="btn-primary w-fit px-5 px-2">Megnézem</a>
                    </div>

                </div>
            </div>
        </>
    },
    {
        id: 7, content:
            <>
                <Game title={"Védd meg a hóembert!"} game={'snowballs/index.html'}/>
            </>
    },
    {
        id: 8, content: <>
            <h2>8. nap</h2>
            <div>
                <img src="assets/box_100_christmas.png" alt="" className={'rounded hidden md:block'}/>
                <img src="assets/box_100_christmas_mobil.png" alt="" className={'rounded md:hidden'}/>
            </div>
        </>
    },
    {
        id: 9, content: <>
            <h2>Barna sörös marharagu gombával és gesztenyés dödöllével</h2>
            <div>
                <img src="assets/barna-soros-marharagu-gombaval-es-gesztenyevel.jpg" alt="" className={'my-4 rounded'}/>
            </div>
            <h4>Ez a recept ötvözi a két, szerintem legszuperebb őszi alapanyagot: a gesztenyét és a gombát, méghozzá
                egy marharagu formájában. Emellett került bele még egy kevés barna sör is, hiszen valljuk be, az nem tud
                elrontani semmit. </h4>
            <div className="grid md:grid-cols-4 gap-x-2">
                <div>
                    <h3>Hozzávalók</h3>
                    <h4 className={'mb-2'}>A raguhoz</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>300 g marhalábszár</li>
                        <li>2 ek sertészsír</li>
                        <li>2 fej vöröshagyma (130 g/fej)</li>
                        <li>só, bors</li>
                        <li>2 tk sűrített paradicsom</li>
                        <li>2 gerezd fokhagyma</li>
                        <li>2 dl barna sör</li>
                        <li>1 tk kakukkfű</li>
                        <li>1 tk majoránna</li>
                        <li>1 tk füstölt paprika</li>
                        <li>1 ek szójaszósz</li>
                        <li>10 g étcsokoládé</li>
                        <li>150 g csiperkegomba</li>
                    </ul>
                    <h4 className={'mb-2'}>A dödölléhez</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>300 g tisztított burgonya</li>
                        <li>só, bors</li>
                        <li>150 g finomliszt (kb.)</li>
                        <li>8 szem főtt gesztenye</li>
                        <li>2 ek sertészsír</li>
                    </ul>
                    <h4 className={'mb-2'}>A tálaláshoz</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>2 ek tejföl</li>
                        <li>4 szem főtt gesztenye</li>
                    </ul>
                </div>
                <div className={'md:col-span-3'}>
                    <p>A sörös marharagu elkészítéséhez a lábszárat 1 x 1 cm-es kockákra vágjuk, majd egy forró, közepes
                        méretű lábasban magas lángon, 1 evőkanál zsíron lepirítjuk. Ha ez megvan, kiszedjük egy tálba,
                        visszavesszük kissé a lángot, félfőre vágjuk a hagymát és mehet is a lábasba a hús helyére, a
                        maradék zsír társaságában. A hagymát sózzuk, majd aranybarnára pirítjuk. Ha kész, rárakjuk a
                        paradicsompürét és átpirítjuk vele, majd mehet vissza rá a hús és a felszeletelt fokhagyma.
                        Ráöntjük a sört, és addig forraljuk vissza, amíg el nem kezd karamellizálódni.
                    </p>
                    <p>
                        Ekkor ráöntjük a szójaszószt, a majoránnát, a kakukkfüvet és a füstölt paprikát, sózzuk és
                        borsozzuk, és ezt követően, mint egy pörköltet, folyamatosan felöntögetve vízzel puhára főzzük a
                        húst.
                    </p>
                    <p>Ha a lábszár már puha, beledobjuk az étcsokit, a gombát mérettől függően negyedeljük vagy
                        felezzük, és ez is mehet hozzá, majd ezután még kb. 10-15 percig főzzük.</p>
                    <h4>
                        A dödölle elkészítése:
                    </h4>
                    <p>
                        A dödölléhez a burgonyát felkockázzuk és egy lábasban felöntjük annyi vízzel, amennyi éppen
                        ellepi, sózzuk, majd puhára főzzük.
                    </p>
                    <h4>A sörös marharagu befejezése és tálalás
                    </h4>
                    <p>
                        Ha megfőtt, a még forró tűzhelyen a vízzel együtt összetörjük, a gesztenyét felvágjuk kis
                        kockákra, hozzáadjuk a liszttel együtt a krumplihoz, és sózzuk, borsozzuk. A liszt mennyiségét
                        sok minden befolyásolhatja, apránként adagoljuk hozzá – a lényeg, hogy egy kissé ragacsos, de
                        formázható tésztát kapjunk. Amikor homogén tésztát kaptunk, lehúzzuk a tűzről.
                    </p>
                    <p>
                        Két kanál segítségével nagyobb galuskákat szaggatunk a tésztából és magas lángon, egy nagy
                        serpenyőben aranybarnára sütjük őket a sertészsíron.
                    </p>
                    <p>Tányérra szedünk a raguból, mellérakjuk a dödölléket, kanalazunk rá a tejfölből és ráreszelünk
                        néhány gesztenyét is.</p>
                </div>
            </div>
        </>
    },
    {
        id: 10, content: <>
            <h2>Hangolódj az Ünnepekre!</h2>
            <div className={'md:flex justify-center my-4 hidden'}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/SK7cpuhwoP0"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
            <div className={'flex justify-center my-4 md:hidden'}>
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/SK7cpuhwoP0"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
            <h4>Fogadd szeretettel ezt a karácsonyi zenét, és éld át az Ünnepek hangulatát.</h4>
        </>
    },
    {
        id: 11,
        content: <>
            <h2>Vizigyöngyös úszógyertya készítés</h2>
            <div className={'md:flex justify-center my-4 hidden'}>
                <iframe width="560" height="600" src="https://www.youtube.com/embed/1REiHCePsMw"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
            <div className={'flex justify-center my-4 md:hidden'}>
                <iframe width="100%" height="600" src="https://www.youtube.com/embed/1REiHCePsMw"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
        </>
    },
    {
        id: 12,
        content: <>
            <h2 className={'text-center mb-5'}>Hány korszakból áll egy férfi élete?</h2>
            <img src="assets/109444.640.jpg" alt="" className={'mx-auto rounded'}/>

        </>
    },
    {
        id: 13, content:
            <>
                <h2>Hangolódj az Ünnepekre!</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/4y7GSbyJu6E"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/4y7GSbyJu6E"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <h4>Fogadd szeretettel ezt a videót, és éld át az Ünnepek hangulatát.</h4>
            </>
    },
    {
        id: 14, content:
            <>
                <h2>Hangolódj az Ünnepekre!</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Cp4RRAEgpeU"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/Cp4RRAEgpeU"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <h4>Tekints meg mi történik épp most, a Santa Claus village-ben.</h4>
            </>
    },
    {
        id: 15, content:
            <>
                <h2 className={'mb-6'}>Karácsonyi ajándék csomagolás ötletek – 11 remek tipp kreatív és egyedi
                    ajándékcsomagoláshoz</h2>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 py-6 border-y-2 '}>
                    <div className={'order-1'}><img src="./assets/csomagolas-1.jpg"
                                                    alt=""
                                                    className={'h-auto max-w-[50%] mx-auto rounded'}/></div>
                    <p className={'order-2 text-lg'}>A karácsonyi mintájú csomagolóanyagok között találhatunk szépeket,
                        de ha
                        valami eredetibbre
                        vágyunk, használhatunk teljesen egyszerű, sima csomagolópapírt, kössünk rá szalagot és díszítsük
                        fehér festékkel, levelekkel, ágakkal.</p>
                </div>

                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 md:order-2'}><img src="./assets/csomagolas-2.jpg"
                                                               alt=""
                                                               className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 md:order-1 text-lg'}>Utánozhatjuk egy postai küldemény megjelenését egy
                        egyszerűbb csomagolópapírral, nagy postabélyegekkel, postabélyegzővel, színes zsinórral átkötve
                        – címezzük meg és írjuk alá, hogy teljes legyen a kép.</p>
                </div>

                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 '}><img src="./assets/csomagolas-3.jpg"
                                                     alt=""
                                                     className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 text-lg'}>A gyerekeknek biztosan örömet szerez majd, ha a
                        klasszikus módszerrel, ajándék tartó zokniba tesszük a kisebb meglepetéseket, ezeket horgokra
                        akasztva lógathatjuk is sorban a fa mellett.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 md:order-2'}><img src="./assets/csomagolas-4.jpg"
                                                               alt=""
                                                               className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 md:order-1 text-lg'}>A kis ajándéktárgyak és édességek nagyszerűen néznek ki
                        szép üvegedényekben, díszítsük madzaggal és kézműves hatású üdvözlőkártyával – az üveg az
                        ajándékozás után is megmarad, emlékeztetőül a szép pillanatokra.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 '}><img src="./assets/csomagolas-5.jpg"
                                                     alt=""
                                                     className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 text-lg'}>Tegyük az ajándékokat előre elkészített vagy házi készítésű
                        dobozokba (utóbbi esetben keményebb kartonból mi is elkészíthetjük a dobozt egy az internetről
                        letöltött sablon alapján). A megvásárolható dobozok között biztosan találunk szépet, de egyedibb
                        lesz az ajándék, ha magunk csomagoljuk egy szép, skandináv mintájú papírba.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 md:order-2'}><img src="./assets/csomagolas-6.jpg"
                                                               alt=""
                                                               className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 md:order-1 text-lg'}>Ha a doboz helyett valami érdekesebb formát szeretnénk,
                        az interneten találhatunk sablonokat tasakokhoz is, vastagabb, szép mintával díszített papírból
                        hajthatjuk össze.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 '}><img src="./assets/csomagolas-7.jpg"
                                                     alt=""
                                                     className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 text-lg'}>Engedjük szabadon a fantáziánkat, hiszen az ajándékokat nem
                        kötelező az unalomig ismert karácsonyi csomagolópapírokba burkolni. Használhatunk erre a célra
                        pl. térképet (utazók nagy örömére), de egy újságpapírból is remek dolgokat hozhatunk ki.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 md:order-2'}><img src="./assets/csomagolas-8.jpg"
                                                               alt=""
                                                               className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 md:order-1 text-lg'}>Maradt valamennyi tapéta a lakásdekorációból?
                        Felhasználhatjuk azt is, de ha ügyesen bánunk a textilekkel, akkor egy maradék anyagból is
                        készíthetünk egyedi csomagdíszt.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 py-6'}>
                    <div className={'order-1'}><img src="./assets/csomagolas-9.jpg"
                                                    alt=""
                                                    className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 text-lg'}>Ha valaki szeretne eltérni a szokványos piros, zöld,
                        télapós, fenyőfás, angyalkás csomagolástól, válasszon elegáns fekete-fehér csomagolópapírokat,
                        kreatív díszítéssel. A fűszerek, bogyók, tobozok szépen passzolnak a fekete-fehér
                        csomagoláshoz.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 md:order-2'}><img src="./assets/csomagolas-10.jpg"
                                                               alt=""
                                                               className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 md:order-1 text-lg'}>Bármelyik csomagolást is választjuk, egy kis extra
                        díszítés nem árthat. Legyünk kreatívak, növényekkel, levelekkel, fenyőágakkal.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 py-6'}>
                    <div className={'order-1 '}><img src="./assets/csomagolas-11.jpg"
                                                     alt=""
                                                     className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 text-lg'}>Egy szép ezüst, fehér, bézs papírt nagyszerűen feldobhatunk kisebb
                        karácsonyi díszekkel, papír csillagokkal, szalagokkal, csipkékkel, textíliákkal és apró
                        figurákkal.</p>
                </div>


            </>
    },
    {
        id: 16, content:
            <>
                <h2>16. nap</h2>
                <video src="assets/videos/udvozlet.mp4" muted autoPlay controls></video>
            </>
    }
    ,
    {
        id: 17, content:
            <>
                <h2>Így készítsd jól a forralt bort!</h2>
                <div>
                    <img src="assets/forralt-bor.jpg" alt="" className={'my-4 rounded'}/>
                </div>
                <h4>A forralt bor az egyik legnépszerűbb téli ital, nem véletlenül! Finom lélekmelegítő, ami ráadásul
                    többféleképp is elkészíthető.</h4>
                <div className="grid md:grid-cols-4 gap-x-2">
                    <div>
                        <h3>Hozzávalók</h3>
                        <h4 className={'mb-2'}>A vörösboroshoz:</h4>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>750 ml száraz vörösbor pl. kékfrankos</li>
                            <li>1 - 2 szem aszalt szilva (opcionális)</li>
                            <li>1 evőkanál olívaolaj</li>
                            <li>2 rúd fahéj</li>
                            <li>1 - 3 szelet friss gyömbér</li>
                            <li>5 db szegfűszeg</li>
                            <li>3 ek cukor</li>
                            <li>1 db csillagánizs</li>
                            <li>200 ml narancslé vagy víz</li>
                            <li>1 db narancs héja</li>
                        </ul>
                        <h4 className={'mb-2'}>A fehérboroshoz:</h4>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>750 ml száraz fehérbor pl. olaszrizling</li>
                            <li>1 db alma kockázva</li>
                            <li>3 ek méz</li>
                            <li>1 ek vaníliás cukor</li>
                            <li>2 rúd fahéj</li>
                            <li>4 - 5 db szegfűszeg</li>
                            <li>1 db citrom héja hámozva</li>
                            <li>150 ml almalé</li>
                        </ul>
                    </div>
                    <div className={'md:col-span-3'}>
                        <p>A forralt bor alapja legtöbbször vörösbor. Nem szükséges a felső kategóriásak közül
                            választani, mert a sokféle fűszernek köszönhetően nem a bor íze érvényesül. Legjobb egy
                            közepes árfekvésű kékfrankost használni hozzá.
                        </p>
                        <p>A bort egy gyorsforralóba öntjük, remekül passzol hozzá az aszalt szilva, de a hagyományos
                            fűszerekről se feledkezzünk meg. Tehetünk bele fahéjat, gyömbért, szegfűszeget, és ízlés
                            szerint ízesítjük cukorral és egy kevés csillagánizzsal is. Ez nagyon intenzív fűszer,
                            csínján bánjunk vele!
                        </p>
                        <p>
                            Az italt vízzel vagy kevés narancslével hígítjuk, ha pedig kedveljük a citrusos vonalat, a
                            narancs héját se hagyjuk ki. Ezt egy zöldséghámozóval szedjük le, arra figyeljünk, hogy a
                            fehér részek ne kerüljenek bele, mert keserűvé teheti a végeredményt.
                        </p>
                        <p>
                            A könnyedebb fehérboros verzióhoz a legideálisabb, ha olaszrizlinggel dolgozunk. Ízesítés és
                            fűszerezés terén az alma, a méz és a vaníliás cukor is fantasztikus ízeket ad neki, de
                            persze a
                            fahéj és a szegfűszeg itt is elmaradhatatlan. Ezek mellett citromhéj és almalé illik hozzá.
                            A
                            bort közepes lángon főzzük körülbelül 15-20 percig, vagy amíg eléri a kívánt forróságot.
                        </p>
                        <p>
                            Az ital alkoholtartalma átlagosan 7-10% körüli, a bor típusától függően. Nem igaz a tévhit,
                            hogy
                            az alkohol elpárolog belőle, ráadásul a hőmérsékletnek és a cukortartalomnak köszönhetően
                            még
                            hamarabb érezhető a hatása!
                        </p>
                        <p>
                            Gyerekeknek, vagy akik nem isznak alkoholt, inkább gyümölcsleves alapot használjunk. Erre a
                            célra az alma- vagy szilvalé lesz a legalkalmasabb, de kísérletezhetünk más ízekkel is. </p>

                    </div>
                </div>
            </>

    }
    ,
    {
        id: 18, content:
            <>
                <h2>Aszaltszilvás-mákos bonbon</h2>
                <div>
                    <img src="assets/aszalt-szilvas-makos-bonbon.jpg" alt="" className={'my-4 rounded'}/>
                </div>
                <h4>Mivel Ti kedvelitek a gyors és egyszerű, mégis finom receptjeinket, mi meg kedvelünk benneteket, így
                    karácsony közeledtével egy villámgyors ehető ajándékot mutatok nektek. Érdemes dupla adagot
                    készíteni, hiszen egészen biztos, ha megkóstolod, elgondolkozol rajta, hogy továbbadd-e. </h4>
                <div className="grid md:grid-cols-4 ">
                    <div>
                        <h3>Hozzávalók</h3>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>100 g háztartási keksz</li>
                            <li>15 g porcukor</li>
                            <li>30 g puha vaj</li>
                            <li>200 g fehér csoki</li>
                            <li>1 tk egész mák</li>
                            <li>100 g aszalt szilva</li>
                            <li>1 db narancs</li>
                            <li>50 g darált mák</li>
                            <li>1 ek olaj</li>
                        </ul>
                    </div>
                    <div className={'col-span-3'}>
                        <p>Az aszaltszilvás-mákos bonbonhoz a kekszet egy konyhai aprítógépben összezúzzuk, egy
                            keverőtálba tesszük, majd az aszalt szilvát is krémesre zúzzuk. Hozzáadjuk a porcukrot, a
                            narancs reszelt héját és a felének a levét, a puha vajat, a darált mákot és az egészet
                            összedolgozzuk. Kis, kb. dióméretű gombócokat formázunk a masszából.
                        </p>
                        <p>
                            A csokoládét vízgőz fölött megolvasztjuk az olajjal, majd belemártjuk a bonbonokat.
                            Lecsepegtetjük, és egy zsírpapírra tesszük a kész bonbonokat, végül a tetejüket megszórjuk
                            egész mákkal.
                        </p>
                        <p>
                            Hagyjuk, hogy megdermedjenek, és már csomagolhatjuk is be, hogy örömet szerezzünk ezzel az
                            ehető ajándékkal a szeretteinknek vagy akár saját magunknak. 😉</p>
                    </div>
                </div>
            </>

    }
    ,
    {
        id: 19, content:
            <>
                <h2>Vörösboros kacsacomb polentával</h2>
                <div>
                    <img src="assets/voros-boros-kacsa.jpg" alt="" className={'my-4 rounded'}/>
                </div>
                <h4>Ha valami, hát ez egy igazi ünnepi étel. Lassan készül és ezért mély, gazdag ízeivel teljesen
                    elvarázsol. Az édeskés-savanykás mártás mellé a krémes, puha polenta tökéletes köret.</h4>
                <div className="grid md:grid-cols-4 gap-x-2">
                    <div>
                        <h3>Hozzávalók</h3>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>4 kacsacomb</li>
                            <li>só és bors</li>
                            <li>1 evőkanál olívaolaj</li>
                            <li>2,5 dl száraz vörös bor</li>
                            <li>10 dkg aszalt meggy</li>
                            <li>10 dkg aszalt feketecseresznye</li>
                            <li>5 dkg mazsola</li>
                            <li>1 fej fokhagyma</li>
                            <li>2 teáskanál kakukkfű</li>
                            <li>1 csipet cayenne bors</li>
                            <li>1 l szárnyas alaplé</li>
                            <li>2 teáskanál kukoricakeményítő</li>
                            <li>2 evőkanál kapribogyó</li>
                            <li>2 evőkanál finomra aprított petrezselyem</li>
                            <li>1 bio citrom héja és leve</li>
                        </ul>
                        <h4 className={'mb-2'}>A polentához:</h4>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>20 dkg kukoricadara</li>
                            <li>1 l víz</li>
                            <li>7 dkg frissen reszelt parmezán</li>
                            <li>5 dkg vaj</li>
                            <li>1 tk só</li>
                        </ul>
                    </div>
                    <div className={'md:col-span-3'}>
                        <p>A kacsacombokon a bőrt beirdaljuk, majd sózzuk és borsozzuk őket. Az olajat közepes lángon
                            felhevítjük egy vaslábasban, majd bőrükkel lefelé rátesszük a kacsacombokat és kíméletesen
                            lepirítjuk őket (8-9 perc alatt). Euztán megfordítjuk a combokat, és további 5 percig
                            pirítjuk. A felesleges zsírt leöntjük és kiszedjük a combokat. A lábasba öntjük a bort,
                            felére beforraljuk, közben felkaparjuk a lábas aljára ragadt sült húsdarabokat. Hozzáadjuk
                            az aszalt gyümölcsöket, a fokhagymát, kakukkfüvet és a csilipelyhet. Visszatesszük a
                            kacsacombokat és felöntjük annyi alaplével, amennyi 2/3 részéig ellepi a húst.
                            Felforraljuk, majd áttesszük a 170 fokra előmelegített sütőbe, ahol 1,5 órá sütjük fedő
                            alatt.
                        </p>
                        <p>Ha puhára sültek, a kacsacombokat kivesszük a lábasból és melegen tartjuk. A lábasban marad
                            mártásról leszedjük a zsírt, a mártást pedig felére beforraljuk, közben hozzáadjuk a
                            gyöngyhagymát. Ha tűl híg a mártás, kikeverünk 1 ek kukoricakeményítőt 2 ek vorös borral és
                            a mártáshoz adjuk, addig főzzük, amíg az besűrűsödik. Végül beállítjuk az ízeket egy kevés
                            citromlével, sóval, borssal. Tálaláskor megszórjuk a maradék petrezselyemmel és krémes
                            polentát adunk mellé.
                        </p>
                        <p>
                            A polentához a vizet felforraljuk, sózzuk és megvárjuk, amíg újra felforr. Lassan
                            beleszórjuk a kukoricadarát és 5 percig keverjük közepesen alacsony láng felett. Néha
                            megkeverve addig főzzük, amig a dara megpuhul és elválik a lábos oldalától. Lehúzzuk a
                            tűzről, hozzákeverjük a puha vajat és a reszelt paremzánt. Tálalásig melegen tartjuk, a
                            tetejére nedves sütőpapírt teszünk, hogy ne bőrösödjön.
                        </p>

                    </div>
                </div>
            </>
    }
    ,
    {
        id: 20, content:
            <>
                <h2>20. nap</h2>
                <img src="assets/winnie.png" alt="" className={'my-4 mx-auto rounded hidden md:block'}/>
                <img src="assets/winnie_mobil.png" alt="" className={'rounded md:hidden'}/>
            </>
    }
    ,
    {
        id: 21, content:
            <>
                <h2>21. nap</h2>
                <div>
                    <video src="assets/videos/winterdance.mp4" className={'my-4 mx-auto'} muted autoPlay loop controls/>
                </div>
            </>
    }
    ,
    {
        id: 22, content:
            <>
                <h2>22.nap</h2>
                <video src="assets/videos/elfrock.mp4" muted autoPlay controls></video>
            </>
    }
    ,
    {
        id: 23, content:
            <>
                <h2>10 hely, ahol az eszem-iszom helyett a játékról szól a csapatépítő</h2>
                <div className={'text-justify mb-6'}>
                    <h3>Sétáljatok a városban!</h3>
                    <div className={'float-right ml-3'}><img src="./assets/csapat-1.jpg"
                                                             alt=""
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Csapatépítés városi sétával? Miért ne?! Szerintünk azért is jó választás, mert amellett, hogy
                        együtt
                        vagytok, a várost is jobban megismeritek, felfedezhettek egy sor olyan helyet, amiről lehet,
                        hogy
                        még nem is tudtatok, ráadásul egy sor budapesti legendáról is hallhattok. Sőt, ezeken az
                        eseményeken
                        nemcsak járkálhattok A-ból B-be, de a beépített játékelemekkel kompetitív és kooperatív
                        csapatépítés
                        is lehet a sétából. Az Imagine-nél a tematikus, a kincskeresős és a rapid városnézés közül
                        válogathattok, de akár teljesen a csapatra szabott programot is rendelhettek, míg a Budastepnél
                        érdemes kipróbálnotok a Várszámháborút.</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Találjátok meg közösen a szabadulás útját a Neverland Bar & Escape Roomban!</h3>
                    <div className={'float-right ml-3'}><img src="./assets/csapat-2.jpg"
                                                             alt=""
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Lehet, hogy elsőre nem jutna eszünkbe, hogy egy szabadulószobás csapatépítőt szervezzünk, holott
                        a
                        logikai feladatok és a rejtélyes fejtörők megoldása elég jól összekovácsolhatja a csapatot,
                        ráadásul
                        ma már az elvarázsolt kastélytól a vadnyugati pókerteremig válogathatunk a különféle tematikus
                        szobák közül. A Dohány utcai Neverland hét, különböző tematikájú szobával bár benneteket, és már
                        csak azért is szuper választás, mert akár 120 fővel is mehettek. Egyszerre 40 fő tud
                        szabadulószobázni, miközben a csapat többi része megebédelhet vagy részt vehet egy italkóstolón,
                        ha
                        pedig a teljes helyszínt kibérlitek, akkor DJ-t vagy karaokét is kérhettek. Kis csapatban
                        érkezőknek
                        – max. 15 fő – pedig a VIP-terem szolgál felejthetetlen és teljesen privát élményként. (x)</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Barkácsoljatok valamit közösen!</h3>
                    <div className={'float-right ml-3'}><img src="./assets/221232.jpg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Mindig jó dolog készíteni valamit, de még jobb, ha ezt közösen teszitek. A napi irodai munka
                        szellemileg elég kimerítő tud lenni, úgyhogy igazi felüdülés, ha lehetőségetek nyílik kétkezi
                        munkával foglalkozni és alkotni, ráadásul itt is rengeteget tanulhattok a csapatmunkáról. Attól
                        pedig egyáltalán nem kell megriadni, ha valaki még életében nem fűrészelt ketté egy lécet vagy
                        nem
                        használt még gyalut, mert a szakemberek megtanítanak rá mindenkit. Ha elmélyülnétek a
                        barkácsolásban, akkor nemcsak magatoknak és a közösségnek készíthettek tárgyakat, de akár
                        kutyamenhelyek számára is készíthettek fából kutyaházakat. A Woodivity minden workshopja kérhető
                        csapatépítőnek is, míg a Creator Nyitott Műhelyben izgalmas kísérlet lehet lemodelleznetek az
                        üzleti
                        folyamatokat.</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Készítsetek finom ételeket egy főzőiskolában!</h3>
                    <div className={'float-right ml-3'}><img src="./assets/csapat-4.jpeg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Ha ti is egy olyan irodában dolgoztok, ahol mindenki imád enni és keresi a jobbnál jobb kulináris
                        élvezeteket, de a közösségi sütögetés és bográcsozás helyett valami másra vágynátok, akkor
                        érdemes a
                        csapatépítőt egy főzőiskolába szervezni. Egy ilyen helyzetben olyan oldalát is megismerhetitek a
                        kollégáitoknak, ami a meetingeken vagy a szomszéd asztalnál ülve nem feltétlenül tűnik fel, de
                        cinkosan össze is mosolyoghattok, ha a receptben írt mennyiség helyett titokban többet tesztek
                        az
                        ételbe. Az már csak hab a tortán, hogy az elkészített finomságokat utána közösen megehetitek. Mi
                        ajánljuk többek között a Makifood Főzőiskolát, a Negura Cooking japán és sri lankai főzőiskola
                        kurzusait vagy az Akademia Italia óráit.</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Korcsolyázzatok a Budapest Parkban!</h3>
                    <div className={'float-right ml-3'}><img src="./assets/csapat-5.jpeg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Már tavaly is imádtuk, hogy az egyik kedvenc koncerthelyszínünk óriási koripályává változott,
                        ahol a
                        melengető italok és a káprázatos fények között igazi varázsvilágban éreztük magunkat. Idén
                        decembertől újra csúszhat mindenki a Budapest Park Jégvilágában, ami szerintünk szuper ötlet, ha
                        télvíz idején szervezitek a csapatépítőt. Sőt, ha nem szeretnétek másokkal közösködni, akkor az
                        egész Jégvilág – vagyis a teljes koripálya, ami idén nagyjából 1500 m² – a tiétek lehet, de
                        kibérelhettek egy kis pályaszakaszt is, ami a backstage felé nyúlik ki. Utóbbi zárt körű, 200
                        fős
                        rendezvény befogadására alkalmas tér, ahonnan akár a nagy pályára is kicsúszhattok, azonban a
                        csapatot senki sem fogja itt zavarni, hiszen a zárt részre csak ti korizhattok be. Ha titeket is
                        felcsigázott a lehetőség, és alig várjátok, hogy ide szervezzétek a csapatépítőt, a
                        sales@budapestpark.hu címen tudtok érdeklődni. (x)</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Keressetek kincseket a városban!</h3>
                    <div className={'float-right ml-3'}><img src="./assets/csapat-6.jpg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Ha a városnézést kiegészítenétek még egy kis extrával, akkor próbáljátok ki a kincskeresést, ahol
                        saját tempóban menetelhettek, fedezhetitek fel a várost, és ebben a kalandban még közös kihívást
                        is
                        találtok. Na de ezeket a játékokat nem is olyan egyszerű megoldani, valódi csapatmunka kell a
                        túra
                        teljesítéséhez, amihez érdemes mindenki ötletét meghallgatni és közösen kitalálni, melyik lehet
                        a
                        legjobb megoldás. Hogy miért? Mert a több kisebb csapat egymással versenyez – legalábbis az
                        UrbanGo
                        pályáin. </p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Tökéletes összhang a Normafa Club House-ban</h3>
                    <div className={'float-right ml-3'}><img src="./assets/csapat-7.jpeg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Az egyik legkedveltebb hely Budapesten a Normafa, ahová mi is gyakran kirándulunk, ha éppen nincs
                        időnk hosszú túrákat tenni, de már nagyon kiszakadnánk a városi létből. Ha valami egészen
                        komplex és
                        holisztikus csapatépítőben gondolkoztok, akkor szuper választás a Budapest tetején lévő Normafa
                        Club
                        House, ahol mindent egyben, tökéletes összhangban érhettek el. A tematikus kompetenciafejlesztő
                        tréningektől kezdve itt mindent megtaláltok, ami egy csoportos elvonuláshoz kell. Míg a
                        tréningtermekben 8-50 fő tud izgalmas workshopokon részt venni, addig a nagy színházteremben
                        akár
                        110 fő is elfér. 60 csodaszép szoba teszi lehetővé a többnapos programok szervezését, a hely
                        konyháján pedig nemzetközi séfgárda biztosítja a kulináris élményt. Egészséges alapanyagok
                        felhasználásával széles választékban rendelhetjük a hazai és a nemzetközi konyha
                        különlegességeit,
                        illetve vegetáriánus, vegán és ajurvédikus ételeket is. Választhatjuk helyszínként továbbá a
                        Dómot,
                        ahol akár 300 fő számára is lehet koncertet rendezni, de más típusú programnak is helyet adhat.
                        Ha
                        pedig Dóm, akkor ha wellnessélményre vágyik a csapat, a kültéri medence hamarosan egy dómmal
                        lesz
                        lefedve, így egy „buborékban” pancsolhattok, emellett pedig egy kültéri jakuzzimedencét is
                        találtok.
                        (x)</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Jótékonykodjatok!</h3>
                    <div className={'float-right ml-3'}><img src="./assets/csapat-8.jpeg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Szerintünk szuper ötlet, ha a „kötelező” programot összekötitek a jótékonysággal, így nemcsak a
                        kollegiális viszony lesz erősebb, de még segítetek is másokon. Süthettek-főzhettek
                        rászorulóknak,
                        készíthettek játékokat gyerekeknek, elmehettek szemetet szedni budapesti erdőkbe és
                        patakpartokra,
                        készíthettek kutyaházat, vagy segíthettek óvodák és iskolák felújításában. Szerintünk mindegyik
                        szép
                        gesztus, ráadásul sokkal motiváltabbak lesztek, ha egy valódi célért dolgoztok össze.</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>Játékos összekovácsolódás a Fröcskölőben</h3>
                    <div className={'float-right ml-3'}><img src="./assets/csapat-9.jpg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Ha közösen szeretnétek megszabadulni a feszkótól, akkor szuper ötlet kipróbálni a Fröcskölőt a
                        Blaha
                        Lujza tér közelében, ami sokkal humánusabb stresszlevezető, mint ha beszabadulnátok egy
                        dühöngőbe
                        törni-zúzni. Ez az a hely, ahol biztos, hogy minden csupa móka és kacagás lesz, főleg, mert a
                        védőruhában kicsit úgy érezhetitek magatokat, mintha a Breaking Bad szereplői lennétek, de a
                        színes
                        festékekkel biztos, hogy nem csak a vásznat fogjátok lefesteni. A Fröcskölő után tegyünk egy
                        sétát a
                        Szelfimúzeumba, és szórakoztassuk kollégáinkat azzal, hogy különböző berendezett háttereknél
                        készítünk együtt egy-egy vicces vagy hatásos szelfit, ami remekül mutathat közös emlékként az
                        iroda
                        falán. Azért is éri meg kitérőt tenni ide, mert egy olyan, kombinált jegyet vásárolhatunk, ami
                        egyszerre érvényes a Fröcskölőbe és a múzeumba is.</p>
                </div>
                <div className={'text-justify mb-6'}>
                    <h3>
                        Fedezzétek fel a főváros környéki kirándulóhelyeket!</h3>
                    <div className={'float-right ml-3'}><img src="./assets/csapat-10.jpg"
                                                             alt="Chez Dodo"
                                                             className={'w-[120px] md:w-[200px] rounded'}/></div>
                    <p>Ha már végképp elegetek van a benti létből, és a céges csapatépítőt is inkább a szabadba
                        terveznétek,
                        akkor fedezzétek fel a Budapest környéki túraútvonalakat, amikből mi is megírtunk már jó párat.
                        Ha
                        nem szeretnétek messzire menni, akkor a fővároson belül is találtok olyan helyeket, amiket a
                        kollégákkal együtt is felfedezhettek, a pihenőréteken pedig megállhattok bográcsozni, de akár a
                        csapatot összekovácsoló feladatokat is játszhattok. Ha hirtelen nincs tippetek, merre menjetek,
                        nyugodtan böngésszetek az e-rdei útvonalak, a kilátók és a különféle kirándulóhelyek között. Mi
                        mindegyiket imádjuk.</p>
                </div>

            </>
    }
    ,
    {
        id: 24, content:
            <>
                <h2>Boldog karácsonyt kívánunk!</h2>
                <div>
                    <video src="assets/videos/karacsony.mp4" className={'my-4 mx-auto'} muted autoPlay loop controls/>
                </div>

            </>
    }
    ,
]

import React from 'react';

const Game = ({game, title}) => {
    return (
        <div>
            <h2>{title}</h2>
            <iframe src={game} width='100%' height='400px' frameBorder="0" className={'my-5'}/>
        </div>
    );
};

export default Game;
